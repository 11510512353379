#osm-button {
  position: absolute;
  bottom: 20px;
  right: 60px;
  z-index: 1000;
}

/* defining the container styles the map sits in */
.map-style{
  width: 100%;
  height: 100vh;
}